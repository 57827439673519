const React = require('react');

const { string, shape } = require('prop-types');

const ActionLabel = require('../../../action-label');
const { ACTION_TYPES } = require('../../utils');

const namespace = 'ui-vip-credits-bau__action-label-component';

const CreditsBauAction = props => {
  const { target, label, action_type: actionType, modal_title: title, track } = props;

  const actionTypeProps = {
    [ACTION_TYPES.LINK]: {
      url: target,
    },
    [ACTION_TYPES.MODAL]: {
      modal: {
        url: target,
        title,
      },
    },
  };

  const stylesLabel = { ...label };
  delete stylesLabel.text;

  const actionProps = {
    linkLabel: { label: stylesLabel, track },
    highlightedText: { text: label.text },
    className: namespace,
    ...actionTypeProps[actionType],
  };

  return <ActionLabel {...actionProps} />;
};

CreditsBauAction.propTypes = {
  target: string.isRequired,
  label: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
  }),
  track: shape({}),
  action_type: string.isRequired,
  modal_title: string.isRequired,
};

CreditsBauAction.defaultProps = {
  target: '/',
  label: null,
  track: null,
  action_type: null,
  modal_title: '',
};

module.exports = { CreditsBauAction };
