const React = require('react');
const { shape, string } = require('prop-types');
const { useContext } = require('react');

const getActionModal = require('../../../../lib/get-action-modal');
const onIframeModalResize = require('../../../../lib/on-iframe-modal-resize');
const StaticPropsContext = require('../../../context/static-props');
const Payment = require('../../../payment');

const BuyBoxOffersPayment = ({ see_more_action, namespace }) => {
  const { deviceType } = useContext(StaticPropsContext);
  const actionModal = getActionModal(
    see_more_action,
    onIframeModalResize('vip:modal-content:loaded', 'height', '.ui-pdp-iframe-modal', '.andes-modal-dialog__header'),
    true,
  );
  const actionType = deviceType === 'mobile' ? see_more_action : actionModal;

  return <Payment action={actionType} className={`${namespace}__action-link`} />;
};

BuyBoxOffersPayment.propTypes = {
  see_more_action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    modal_title: string.isRequired,
    target: string.isRequired,
  }),
  namespace: string,
};
BuyBoxOffersPayment.defaultProps = {
  namespace: null,
};

module.exports = BuyBoxOffersPayment;
