const React = require('react');

const { useEffect, useRef, useState } = React;
const { useContext } = require('react');
const { arrayOf, string, bool, shape, number } = require('prop-types');
const classNames = require('classnames');
const Card = require('@andes/card');
const { trackEvent } = require('../../lib/tracking');

const CardContent = require('./card-content');
const IconFactory = require('../icons/factory-builder')();
const { CardTooltipSkeleton } = require('../placeholder');
const StaticPropsContext = require('../context/static-props');
const { isMshops } = require('../../utils/mercadoShopsUtils');

const useViewCounter = require('../../hooks/use-view-counter');

const namespace = 'ui-pdp-card-tooltip';

const ARROW_SIDE = {
  CENTER: 'center',
  RIGHT: 'right',
  LEFT: 'left',
};

const getArrowSide = arrowType => ARROW_SIDE[String(arrowType)];

const ARROW_POSITION = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const getArrowPosition = arrowPosition => ARROW_POSITION[String(arrowPosition)];

const CardTooltip = ({
  component_id,
  className,
  title,
  icon,
  arrow_type,
  arrow_position,
  closeable,
  storable,
  subtitles,
  close_track,
  has_shadow,
  id,
  progress_bar,
  isFetching,
  highlight,
  userConsentCookie,
  action,
}) => {
  const [visible, setVisibility] = useState(true);
  const [height, setHeight] = useState(0);
  const { site } = useContext(StaticPropsContext);
  const isShops = isMshops({ platform: { id: site } });

  const updateCloseStatus = useViewCounter({
    id: component_id || id,
    visible,
    storable,
    cookieName: 'tooltips-configuration',
    userConsent: userConsentCookie?.find(category => category === 'advertising')?.consent,
  });
  const ref = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current && ref.current.clientHeight && !isFetching) {
      setHeight(ref.current.clientHeight);
    }
  });

  const onCloseHandler = () => {
    if (storable) {
      updateCloseStatus();
      setVisibility(false);
    }
    trackEvent(close_track);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={classNames({ [`${namespace}`]: !isShops }, className)} ref={ref}>
      <Card
        className={classNames(`${namespace}__card`, {
          [`${namespace}__card--arrow-${getArrowPosition(arrow_position)}-${getArrowSide(arrow_type)}`]: arrow_type,
          [`${namespace}__card--has-shadow`]: has_shadow,
          'andes-tooltip--highlight': highlight,
        })}
      >
        {closeable && (
          <span
            role="button"
            tabIndex="0"
            onKeyDown={onCloseHandler}
            onClick={onCloseHandler}
            className={`${namespace}__card__icon--close`}
          >
            {IconFactory({ id: 'CLOSE', color: !highlight ? 'BLACK' : 'WHITE' })}
          </span>
        )}
        {isFetching ? (
          <CardTooltipSkeleton hasIcon={!!icon} hasProgressBar={!!progress_bar} height={height} />
        ) : (
          <CardContent title={title} icon={icon} subtitles={subtitles} progressBar={progress_bar} action={action} />
        )}
      </Card>
    </div>
  );
};

CardTooltip.propTypes = {
  id: string,
  component_id: string,
  className: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
  icon: shape({
    id: string.isRequired,
  }),
  arrow_type: string,
  arrow_position: string,
  has_shadow: bool.isRequired,
  closeable: bool.isRequired,
  storable: bool.isRequired,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
    }),
  ),
  close_track: shape({}),
  progress_bar: shape({
    percentage: number,
    color: string,
    bg_color: string,
  }),
  isFetching: bool,
  highlight: bool,
  userConsentCookie: shape({
    category: string,
    consent: bool,
  }),
  action: shape({
    target: string.isRequired,
    label: shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }),
    track: shape({}),
    action_type: string.isRequired,
  }),
};

CardTooltip.defaultProps = {
  id: null,
  className: '',
  icon: null,
  arrow_type: null,
  arrow_position: 'TOP',
  close_track: null,
  subtitles: null,
  progress_bar: null,
  component_id: null,
  isFetching: false,
  highlight: false,
  action: null,
};

module.exports = CardTooltip;
