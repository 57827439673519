const React = require('react');

const { useContext } = React;
const { bool } = require('prop-types');

const StockInformationMobile = require('../../../stock-information/stock-information.mobile');
const StockInformationDesktop = require('../../../stock-information/stock-information.desktop');
const MoqInformationMobile = require('../../../moq-information/moq-information.mobile');
const MoqInformationDesktop = require('../../../moq-information/moq-information.desktop');
const StaticPropsContext = require('../../../context/static-props');
const ComponentByKey = require('../../../../utils/component-by-key');

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersWarningMessage = props => {
  const { deviceType } = useContext(StaticPropsContext);
  const StockInformation = deviceType === 'mobile' ? StockInformationMobile : StockInformationDesktop;
  const MoqInformation = deviceType === 'mobile' ? MoqInformationMobile : MoqInformationDesktop;

  return (
    <div className={`${namespace}__offer-generic-summary`}>
      <ComponentByKey
        components={{
          stock_information: propsShipping => <StockInformation {...propsShipping} />,
          moq_information: propsCoupon => <MoqInformation {...propsCoupon} />,
        }}
        useId
        {...props}
      />
    </div>
  );
};

BuyBoxOffersWarningMessage.propTypes = {
  useId: bool.isRequired,
};

module.exports = BuyBoxOffersWarningMessage;
