const React = require('react');

const { bool } = require('prop-types');
const ComponentByKey = require('../../../../utils/component-by-key');
const { noFeatureFlag: CpgSummaryFeature } = require('../../../../pages/pdp/features/cpg/summary.mobile');
const { noFeatureFlag: CbtSummaryFeature } = require('../../../cbt/cbt-summary.mobile');
const { noFeatureFlag: CbtTaxesSummaryFeature } = require('../../../cbt-taxes/cbt-taxes-summary.mobile');
const {
  noFeatureFlag: CbtTaxesFcUsSummaryFeature,
} = require('../../../cbt-taxes-fc-us/cbt-taxes-fc-us-summary.mobile');
const {
  noFeatureFlag: CouponSummaryFeature,
} = require('../../../../pages/pdp/features/buybox/coupon-summary/coupon-summary.mobile');
const { noFeatureFlag: ShippingSummaryFeature } = require('../../../../pages/pdp/features/buybox/shipping');
const {
  noFeatureFlag: SecondShippingSummaryFeature,
} = require('../../../../pages/pdp/features/buybox/second-shipping');
const { noFeatureFlag: PickUpSummaryFeature } = require('../../../../pages/pdp/features/buybox/pick-up');
const {
  noFeatureFlag: ReturnsSummaryFeature,
} = require('../../../../pages/pdp/features/buybox/returns-summary/returns-summary.mobile');
const { noFeatureFlag: MeliplusSummaryFeature } = require('../../../../pages/pdp/features/buybox/meliplus');
const {
  noFeatureFlag: UpsellingMeliplusSummaryFeature,
} = require('../../../../pages/pdp/features/buybox/upselling-meliplus');

const {
  noFeatureFlag: CardWithThumbnailsFeature,
} = require('../../../../pages/pdp/features/card-with-thumbnails/card-with-thumbnails');

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersGenericSummary = props => (
  <div className={`${namespace}__offer-generic-summary`}>
    <ComponentByKey
      components={{
        cpg_summary: CpgSummaryFeature,
        cbt_summary: CbtSummaryFeature,
        cbt_taxes_summary: CbtTaxesSummaryFeature,
        cbt_taxes_fc_us_summary: CbtTaxesFcUsSummaryFeature,
        coupon_summary: CouponSummaryFeature,
        shipping_summary: ShippingSummaryFeature,
        second_shipping_summary: SecondShippingSummaryFeature,
        pick_up_summary: PickUpSummaryFeature,
        returns_summary: ReturnsSummaryFeature,
        meliplus_summary: MeliplusSummaryFeature,
        card_with_thumbnails: CardWithThumbnailsFeature,
        upselling_meliplus_summary: UpsellingMeliplusSummaryFeature,
      }}
      useId
      {...props}
    />
  </div>
);

BuyBoxOffersGenericSummary.propTypes = {
  useId: bool.isRequired,
};

module.exports = BuyBoxOffersGenericSummary;
