const React = require('react');
const { arrayOf, bool, func, number, shape, string, node } = require('prop-types');
const classnames = require('classnames');

const { useContext, useState, useEffect, useCallback } = React;
const { RadioButton } = require('@andes/radio-button');
const { ListItem } = require('@andes/list');
const { List } = require('@andes/list');

const { trackEvent } = require('../../lib/tracking');
const StaticPropsContext = require('../context/static-props');
const ComponentByKey = require('../../utils/component-by-key');
const BuyBoxOffersPrice = require('./components/buy-box-offers-price/buy-box-offers-price');
const BuyBoxOffersSeller = require('./components/buy-box-offers-seller/buy-box-offers-seller');
const BuyBoxOffersGenericSummary = require('./components/buy-box-offers-generic-summary/buy-box-offers-generic-summary');
const BuyBoxOffersPayment = require('./components/buy-box-offers-payment/buy-box-offers-payment');
const BuyBoxOffersPromotions = require('./components/buy-box-offers-promotions');
const BuyBoxOffersPromotionsTop = require('./components/buy-box-offers-promotions-top');
const BuyBoxOffersPromotionsBottom = require('./components/buy-box-offers-promotions-bottom');
const BuyBoxOffersWarningMessage = require('./components/buy-box-offers-warning-message/buy-box-offers-warning-message');
const BuyBoxOffersUiMessage = require('./components/buy-box-offers-ui-message/buy-box-offers-ui-message');
const BuyBoxOffersGenericTooltip = require('./components/buy-box-offers-generic-tooltip/buy-box-offers-generic-tooltip');
const BuyBoxOffersExtras = require('./components/buy-box-offers-extras/buy-box-offers-extras');
const BuyBoxOffersTooltipCardMobile = require('./components/buy-box-offers-tooltip-card/buy-box-offers-tooltip-card.mobile');
const BuyBoxOffersHighlightsInformation = require('./components/buy-box-offers-highlights-information/buy-box-offers-highlights-information');

const HistoryService = require('../../services/history');
const { runSchedulerTask } = require('../../utils/validators');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffers = ({
  children,
  cards_credits_component,
  see_more_action,
  items,
  closeModalLabel,
  tags,
  payment_action,
  tooltip,
  onPaymentMethodSelected,
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const [showTooltip, setTooltipVisibility] = useState(tooltip && !tooltip.closed);
  const [itemsList, setItemsList] = useState([]);
  const [itemSelected, setItemSelected] = useState(items[0].selected);
  const firstTimeLoad = true;
  const MAX_HEIGHT_DEFAULT = '2000px';
  const TIMEOUT_DEFAULT_COLLAPSE = 300;
  const EXTRA_PADDING = 32;
  const EXTRA_SCROLL = 50;

  const collapseItemAccordion = () => {
    const notSelectorItem = document.querySelector(`.${namespace}__offer-list-item--NOT-SELECTED`);
    const selectorItem = document.querySelector(`.${namespace}__offer-list-item--SELECTED`);
    const radioButtonSelected = selectorItem.querySelector(`.andes-radio`);
    const priceSelected = selectorItem.querySelector(`.${namespace}__offer-price`);

    selectorItem.style.maxHeight = `${priceSelected.offsetHeight + radioButtonSelected.offsetHeight + EXTRA_PADDING}px`;
    selectorItem.style.overflow = 'hidden';
    notSelectorItem.style.overflow = 'hidden';
  };

  const getItemHeightAccordion = useCallback(() => {
    const selectorItem = document.querySelector(`.${namespace}__offer-list-item--SELECTED`);
    const notSelectorItem = document.querySelector(`.${namespace}__offer-list-item--NOT-SELECTED`);
    const radioButtonNotSelected = notSelectorItem.querySelector(`.andes-radio`);
    const priceNotSelected = notSelectorItem.querySelector(`.${namespace}__offer-price`);

    selectorItem.style.maxHeight = MAX_HEIGHT_DEFAULT;
    notSelectorItem.style.maxHeight = `${priceNotSelected?.offsetHeight +
      radioButtonNotSelected?.offsetHeight +
      EXTRA_PADDING}px`;
    setTimeout(() => {
      selectorItem.style.overflow = '';
      notSelectorItem.style.overflow = '';
    }, TIMEOUT_DEFAULT_COLLAPSE);
  }, [EXTRA_PADDING]);

  const setItemHeightAccordion = useCallback(() => {
    itemsList.map(() => getItemHeightAccordion());
  }, [getItemHeightAccordion, itemsList]);

  const handlePaymentMethodSelected = ({ event, offerType, selectionTrack, selected, keyDown }) => {
    if (selected || (keyDown && event.keyCode !== 13 && event.keyCode !== 32)) {
      return;
    }
    if (event) {
      event.preventDefault();
    }

    const element = document.querySelector('.ui-pdp-buy-box-offers__offer-list');
    const positionY = element.getBoundingClientRect().top + window.scrollY - EXTRA_SCROLL;

    trackEvent(selectionTrack);
    setTimeout(() => {
      collapseItemAccordion();
      window.scrollTo({ top: positionY, behavior: 'smooth' });
      onPaymentMethodSelected(event, offerType);
      runSchedulerTask(() => {
        HistoryService.pushParam('offer_type', offerType, true);
      }, 'background');
    }, TIMEOUT_DEFAULT_COLLAPSE);
  };

  const getComponentsByDevice = () => {
    switch (deviceType) {
      case 'mobile':
        return {
          pill_information: BuyBoxOffersPromotionsTop,
          price: BuyBoxOffersPrice,
          promotions: BuyBoxOffersPromotions,
          promotions_bottom: BuyBoxOffersPromotionsBottom,
          progress_bar: BuyBoxOffersPromotions,
          generic_summary: BuyBoxOffersGenericSummary,
          seller: BuyBoxOffersSeller,
          ui_message: BuyBoxOffersUiMessage,
          tooltip: BuyBoxOffersGenericTooltip,
          warning_message: BuyBoxOffersWarningMessage,
          highlights_information: BuyBoxOffersHighlightsInformation,
        };
      default:
        return {
          price: propsPrice => <BuyBoxOffersPrice {...propsPrice} />,
        };
    }
  };

  useEffect(() => {
    const listArray = document.querySelectorAll(`.${namespace}__offer-list-item`);
    setItemsList(Array.from(listArray));
    setItemSelected(items[0].selected);
  }, [itemSelected, items]);

  useEffect(() => {
    if (firstTimeLoad || itemSelected !== items[0].selected) {
      setItemHeightAccordion();
    }
  }, [firstTimeLoad, itemSelected, items, setItemHeightAccordion]);

  return (
    <>
      <div className={`${namespace}__${deviceType}`}>
        <List withDividers className={`${namespace}__offer-list`}>
          {items.map(item => (
            <React.Fragment key={item.type}>
              <ListItem
                className={classnames(`${namespace}__offer-list-item`, {
                  [`${namespace}__offer-list-item--SELECTED`]: item.selected,
                  [`${namespace}__offer-list-item--NOT-SELECTED`]: !item.selected,
                })}
              >
                <div
                  role="presentation"
                  className={`${namespace}__content-wrapper`}
                  onClick={e =>
                    handlePaymentMethodSelected({
                      event: e,
                      offerType: item.type,
                      selectionTrack: item.selection_track,
                      selected: item.selected,
                    })
                  }
                  onKeyDown={e =>
                    handlePaymentMethodSelected({
                      event: e,
                      offerType: item.type,
                      selectionTrack: item.selection_track,
                      selected: item.selected,
                      keyDown: true,
                    })
                  }
                >
                  <RadioButton
                    id={item.item_id}
                    name={item.item_id}
                    value={item.item_id}
                    checked={item.selected}
                    label={!item.title.text_icon && item.title.text}
                    srLabel={item.title.text_icon && item.title.text}
                    suffix={
                      item.title.text_icon && (
                        <label className={`andes-radio__label ${namespace}__with-icon`} htmlFor={item.item_id}>
                          <span>{item.title.text}</span>
                          {IconFactory({ id: 'cockade' }, 'ui-pdp-cockade-icon')}
                        </label>
                      )
                    }
                  />
                  <div className={`${namespace}__offer-content`}>
                    {item?.components &&
                      item.components.map(component => (
                        <React.Fragment key={component.id}>
                          <ComponentByKey components={getComponentsByDevice()} {...component} />
                        </React.Fragment>
                      ))}
                  </div>
                  {children && item.selected && <div className={`${namespace}__offer-list-children`}>{children}</div>}
                </div>
              </ListItem>
            </React.Fragment>
          ))}
          {see_more_action && (
            <ListItem
              className={classnames(`${namespace}__offer-list-item`, `${namespace}__offer-see-more`, {
                [`${namespace}__offer-list-item--hidden`]: !see_more_action,
              })}
            >
              <BuyBoxOffersPayment namespace={namespace} see_more_action={see_more_action} />
            </ListItem>
          )}
        </List>
        <BuyBoxOffersExtras
          cards_credits_component={cards_credits_component}
          tags={tags}
          payment_action={payment_action}
          tooltip={tooltip}
          showTooltip={showTooltip}
          closeModalLabel={closeModalLabel}
        />
      </div>
      {deviceType === 'mobile' && tooltip && (
        <BuyBoxOffersTooltipCardMobile
          tooltip={tooltip}
          setTooltipVisibility={setTooltipVisibility}
          showTooltip={showTooltip}
        />
      )}
    </>
  );
};

BuyBoxOffers.propTypes = {
  cards_credits_component: shape({}),
  children: node,
  className: string,
  closeModalLabel: shape({}),
  items: arrayOf(
    shape({
      item_id: string.isRequired,
      price: shape({
        id: string.isRequired,
        type: string.isRequired,
        price: shape({
          type: string.isRequired,
          currency_symbol: string.isRequired,
          value: number.isRequired,
          original_value: number,
        }),
      }),
      selected: bool.isRequired,
      title: shape({
        text: string,
        text_icon: shape({}),
      }),
      type: string.isRequired,
    }),
  ).isRequired,
  onPaymentMethodSelected: func.isRequired,
  payment_action: shape({}),
  see_more_action: shape({
    label: shape({
      text: string.isRequired,
    }).isRequired,
    modal_title: string.isRequired,
    target: string.isRequired,
  }),
  tags: arrayOf(shape({})),
  tooltip: shape({}),
};

BuyBoxOffers.defaultProps = {
  cards_credits_component: null,
  children: null,
  className: '',
  closeModalLabel: null,
  payment_action: null,
  see_more_action: null,
  tags: null,
  tooltip: null,
};

module.exports = BuyBoxOffers;
