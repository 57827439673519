const React = require('react');

const { useContext } = React;
const { number, shape, string } = require('prop-types');
const StaticPropsContext = require('../../../context/static-props');
const Price = require('../../../price/price');
const { PriceFontSize } = require('../../../../lib/num-font-size-enum');

const FONT_SIZE_DESKTOP = 28;
const FONT_SIZE_MOBILE = 36;
const DISCOUNT_FONT_SIZE = 14;
const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersPrice = props => {
  const { deviceType } = useContext(StaticPropsContext);
  const { price, discount_label, font_size } = props;

  const device_font_size = deviceType === 'desktop' ? FONT_SIZE_DESKTOP : FONT_SIZE_MOBILE;

  return (
    <div className={`${namespace}__offer-price`}>
      <Price
        className={font_size && `ui-pdp-size--${font_size}`}
        {...{
          ...props,
          value: price.value,
          currencySymbol: price.currency_symbol,
          originalValue: price.original_value,
          discountLabel: discount_label,
          num_font_size: font_size ? PriceFontSize.PRICE_NOT_SELECTED[font_size] : device_font_size,
          original_value_num_font_size: font_size
            ? PriceFontSize.ORIGINAL_PRICE_RESULT
            : PriceFontSize.ORIGINAL_DEFAULT,
          discount_num_font_size: font_size && DISCOUNT_FONT_SIZE,
        }}
      />
    </div>
  );
};

BuyBoxOffersPrice.propTypes = {
  price: shape({
    id: string.isRequired,
    type: string.isRequired,
    price: shape({
      type: string.isRequired,
      currency_symbol: string.isRequired,
      value: number.isRequired,
      original_value: number,
    }),
  }).isRequired,
  discount_label: shape({
    text: string,
    color: string,
    value: string,
  }),
  font_size: string,
};

BuyBoxOffersPrice.defaultProps = {
  discount_label: null,
  font_size: null,
};

module.exports = BuyBoxOffersPrice;
