const React = require('react');
const { string, shape, func } = require('prop-types');
const classNames = require('classnames');

const StyledLabel = require('../styled-label');
const MediaAction = require('../media/media-action');
const { trackEvent } = require('../../lib/tracking');

const namespace = 'ui-pdp-action-label-component';

const ActionLabel = ({ linkLabel, url, target, highlightedText, modal, onClick, className }) => (
  <div className={classNames(`${namespace}__property-link`, className)}>
    <StyledLabel className={classNames({ [`${className}__label`]: className })} {...linkLabel.label} s="span" />
    <MediaAction
      label={highlightedText}
      target={url}
      htmlTarget={target}
      onClick={() => {
        trackEvent(linkLabel.track);
        if (onClick) {
          onClick();
        }
      }}
      modal={modal}
      className={`${className}__media`}
    />
  </div>
);

ActionLabel.propTypes = {
  linkLabel: shape({
    label: shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }).isRequired,
  }),
  url: string,
  target: string,
  highlightedText: shape({
    text: string.isRequired,
    color: string,
    font_size: string,
    font_family: string,
  }).isRequired,
  modal: shape({
    title: string.isRequired,
    url: string.isRequired,
  }),
  onClick: func,
  className: string,
};

ActionLabel.defaultProps = {
  linkLabel: null,
  modal: null,
  onClick: null,
  className: null,
};

module.exports = ActionLabel;
