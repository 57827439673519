const React = require('react');
const { arrayOf, bool, string, shape, number } = require('prop-types');
const ProgressIndicatorLinear = require('@andes/progress-indicator-linear');
const componentEnhance = require('../../lib/component-enhance');
const StyledLabel = require('../styled-label');

const IconFactory = require('../icons/factory-builder')();

const { CreditsBauAction } = require('./builders');

const namespace = 'ui-pdp-card-tooltip__card__content';

const CardContent = ({ title, icon, subtitles, progressBar, action }) => (
  <div className={namespace}>
    {icon && IconFactory(icon, `${namespace}__icon`)}
    <div className={`${namespace}__texts`}>
      {title && (
        <StyledLabel
          as="p"
          {...title}
          text={componentEnhance.jsx(title.text, title.values)}
          className={`${namespace}__title`}
          withDangerous={!title.values}
        />
      )}
      {progressBar && (
        <div className={`${namespace}__progressBar__container`}>
          <ProgressIndicatorLinear
            color={progressBar.color}
            highlight={progressBar.show_highlight}
            value={progressBar.percentage}
          />
        </div>
      )}
      {subtitles &&
        subtitles.length > 0 &&
        subtitles.map(subtitle => (
          <StyledLabel
            as="p"
            className={`${namespace}__subtitle`}
            key={subtitle.text}
            {...subtitle}
            text={componentEnhance.jsx(subtitle.text, subtitle.values)}
          />
        ))}
      {action && (
        <div className={`${namespace}__action`}>
          <CreditsBauAction {...action} />
        </div>
      )}
    </div>
  </div>
);

CardContent.propTypes = {
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
  icon: shape({
    id: string.isRequired,
  }),
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
    }),
  ),
  progressBar: shape({
    percentage: number,
    color: string,
    bg_color: string,
    show_highlight: bool,
  }),
  action: shape({
    target: string.isRequired,
    label: shape({
      text: string.isRequired,
      color: string,
      font_size: string,
      font_family: string,
    }),
    track: shape({}),
    action_type: string.isRequired,
  }),
};

CardContent.defaultProps = {
  icon: null,
  subtitles: null,
  progressBar: {
    percentage: null,
    color: null,
    bg_color: null,
    show_highlight: true,
  },
  action: null,
};

module.exports = CardContent;
