const React = require('react');

const { bool } = require('prop-types');
const ComponentByKey = require('../../../../utils/component-by-key');
const CardTooltip = require('../../../card-tooltip');

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersGenericTooltip = props => (
  <div className={`${namespace}__offer-generic-summary`}>
    <ComponentByKey
      components={{
        paypal_tooltip: propsPaypalTooltip => <CardTooltip {...propsPaypalTooltip} />,
      }}
      useId
      {...props}
    />
  </div>
);

BuyBoxOffersGenericTooltip.propTypes = {
  useId: bool.isRequired,
};

module.exports = BuyBoxOffersGenericTooltip;
