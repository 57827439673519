import { loadable } from 'nordic/lazy';
import compose from '../../../../../lib/compose';
import connect from '../../../../../store/connect';
import withFeatureFlag from '../../../../../components/feature-flag/with-feature-flag';
import actions from '../../../../../actions';

const CouponSumary = loadable(() => import('../../../../../components/buybox/coupon-summary/coupon-summary.mobile'));

const FEATURE_NAME = 'coupon_summary';

const COUPON_PROPS = {
  id: FEATURE_NAME,
};

const mapStateToProps = ({ components: { [FEATURE_NAME]: couponSummary } }, ownProps) => ({
  ...couponSummary,
  ...ownProps,
  ...COUPON_PROPS,
});

const serializeOwnProps = (state, ownProps) => ({
  ...ownProps,
  ...COUPON_PROPS,
});

const mapDispatchToProps = dispatch => ({
  onCouponUpdate: () => dispatch(actions.mobileUpdateCoupon()),
  showSnackbar: snackbar => dispatch(actions.showSnackbar(snackbar)),
  onCouponActivate: params => dispatch(actions.mobileActivateCoupon(params)),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

const noFeatureFlagConnect = connect(
  serializeOwnProps,
  mapDispatchToProps,
);

const noFeatureFlag = noFeatureFlagConnect(CouponSumary);

export default enhance(CouponSumary);

export { mapDispatchToProps, noFeatureFlag };
