const React = require('react');

const { bool } = require('prop-types');
const ComponentByKey = require('../../../../utils/component-by-key');
const {
  noFeatureFlag: MeliplusLabelFeature,
} = require('../../../../pages/pdp/features/buybox/meliplus-label/meliplus-label');

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersHighlightsInformation = props => (
  <div className={`${namespace}__offer-highlights-information`}>
    <ComponentByKey
      components={{
        meliplus_label_summary: MeliplusLabelFeature,
      }}
      useId
      {...props}
    />
  </div>
);

BuyBoxOffersHighlightsInformation.propTypes = {
  useId: bool.isRequired,
};

module.exports = BuyBoxOffersHighlightsInformation;
