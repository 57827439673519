const React = require('react');

const { useContext } = React;
const { string } = require('prop-types');
const StaticPropsContext = require('../../../context/static-props');
const withMappedProps = require('../../../../utils/withMappedProps');
const SellerInfoMapJsonToProps = require('../../../seller-info-label/json-to-props');
const Seller = withMappedProps(require('../../../seller-info-label/seller-info.mobile'))(SellerInfoMapJsonToProps);

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersSeller = props => {
  const { deviceType } = useContext(StaticPropsContext);

  return <div className={`${namespace}__offer-seller`}>{deviceType === 'mobile' && <Seller {...props} />}</div>;
};

BuyBoxOffersSeller.propTypes = {
  deviceType: string.isRequired,
};

module.exports = BuyBoxOffersSeller;
