const React = require('react');

const { bool } = require('prop-types');
const ComponentByKey = require('../../../../utils/component-by-key');
const ShippingMessage = require('../../../shipping-message');

const namespace = 'ui-pdp-buy-box-offers';

const BuyBoxOffersUiMessage = props => (
  <div className={`${namespace}__offer-generic-summary`}>
    <ComponentByKey
      components={{
        item_status_short_description_message: propsShippingMessage => <ShippingMessage {...propsShippingMessage} />,
      }}
      useId
      {...props}
    />
  </div>
);

BuyBoxOffersUiMessage.propTypes = {
  useId: bool.isRequired,
};

module.exports = BuyBoxOffersUiMessage;
