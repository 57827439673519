const React = require('react');
const { bool, shape } = require('prop-types');

const TooltipCardMobile = require('../../../price/components/tooltip-card/tooltip-card.mobile');
const useTooltipWithFrequencyStatus = require('../../../../hooks/use-tooltip-frequency');

const CURRENCY_TOOLTIP_ID = 'currency_tooltip';

const BuyBoxOffersTooltipCardMobile = ({ tooltip, setTooltipVisibility, showTooltip }) => {
  const [showCurrencyTooltip, toggleCurrencyTooltipVisibility] = useTooltipWithFrequencyStatus(CURRENCY_TOOLTIP_ID, -1);
  const isCurrencyTooltip = tooltip && tooltip.id === CURRENCY_TOOLTIP_ID;
  const showCurrencyPriceTooltip = isCurrencyTooltip ? showCurrencyTooltip : showTooltip;

  return (
    showCurrencyPriceTooltip && (
      <TooltipCardMobile {...{ tooltip, setTooltipVisibility, showTooltip, toggleCurrencyTooltipVisibility }} />
    )
  );
};

BuyBoxOffersTooltipCardMobile.propTypes = {
  setTooltipVisibility: bool.isRequired,
  showTooltip: bool.isRequired,
  tooltip: shape({}).isRequired,
};

module.exports = BuyBoxOffersTooltipCardMobile;
