const React = require('react');
const { arrayOf, bool, shape } = require('prop-types');
const { useContext } = require('react');

const StaticPropsContext = require('../../../context/static-props');
const PriceExtrasMobile = require('../../../price/components/price-extras/price-extras.mobile');
const PriceExtrasDesktop = require('../../../price/components/price-extras/price-extras.desktop');

const BuyBoxOffersExtras = ({
  cards_credits_component,
  tags,
  payment_action,
  tooltip,
  showTooltip,
  closeModalLabel,
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const BuyBoxOffersExtrasComponent = deviceType === 'mobile' ? PriceExtrasMobile : PriceExtrasDesktop;
  const closeModal = deviceType === 'mobile' ? {} : { closeModalLabel };

  return (
    <BuyBoxOffersExtrasComponent
      cards_credits_component={cards_credits_component}
      price_tags={tags}
      discounts_tags={tags}
      action={payment_action}
      tooltip={tooltip}
      showTooltip={showTooltip}
      {...closeModal}
    />
  );
};

BuyBoxOffersExtras.propTypes = {
  cards_credits_component: shape({}),
  closeModalLabel: shape({}),
  payment_action: shape({}),
  tags: arrayOf(shape({})),
  tooltip: shape({}),
  showTooltip: bool,
};
BuyBoxOffersExtras.defaultProps = {
  cards_credits_component: null,
  closeModalLabel: null,
  payment_action: null,
  tags: null,
  tooltip: null,
  showTooltip: null,
};

module.exports = BuyBoxOffersExtras;
